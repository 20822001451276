import React from 'react'
import { graphql } from 'gatsby'
import { RichText, Link } from 'prismic-reactjs'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrelaunchGatedContent from '../components/preLaunchGatedContent'
import linkResolver from '../utils/linkResolver'
import PManagerDemoForm from '../components/pManagerGatedDemo'

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

import '../components/article.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

export const query = graphql`
query getPosts($uid: String) {
  prismic {
    allPosts(uid: $uid) {
      edges {
        node {
          title
          meta_image
          _linkType
          meta_description
          meta_title
          body {
            ... on PRISMIC_PostBodyRich_text_block {
              type
              label
              primary {
                rich_text_content
              }
            }
            ... on PRISMIC_PostBodyBig_image {
              type
              label
              primary {
                big_image
                big_image_caption
              }
            }
            ... on PRISMIC_PostBodyNormal_image {
              type
              label
              primary {
                normal_image
                normal_image_caption
              }
            }
            ... on PRISMIC_PostBodyAuthor_block {
              type
              label
              primary {
                author_blurb
                author_cta_text
                author_image
                author_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC_Success_story {
                    success_story_title
                    _linkType
                    _meta {
                      uid
                      type
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    target
                    _linkType
                    url
                  }
                  ... on PRISMIC_Pricing {
                    pricing_title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                }
              }
            }
            ... on PRISMIC_PostBodyCreator_spotlight {
              type
              label
              primary {
                creator_blurb
                creator_image
              }
            }
            ... on PRISMIC_PostBodyCustom_html {
              type
              label
              primary {
                custom_html
              }
            }
            ... on PRISMIC_PostBodySharing {
              type
              label
            }
            ... on PRISMIC_PostBodyPreLaunch_gated_content {
              type
              label
              primary {
                gated_section_blurb
                gated_section_image
                gated_section_cta_text
                gated_section_title
              }
            }
            ... on PRISMIC_PostBodyPManager_demo_form {
              type
              label
              primary {
                ac_form_id
                pmanager_blurb
                pmanager_cta_text
              }
            }
          }
          _meta {
            uid
            type
            tags
          }
        }
      }
    }
  }
}
`

const Post = props => {

  if(!props) return null;

  const article = props.data.prismic.allPosts.edges[0];
  
  if(!article) return null;

  return (
    <Layout>

      <SEO 
        title={RichText.asText(article.node.title)} 
        image={article.node.meta_image.url} 
        description={RichText.asText(article.node.meta_description)} 
        meta={[
          {
            property: `og:image`,
            content: article.node.meta_image.facebook.url
          },{
            property: `twitter:image`,
            content: article.node.meta_image.twitter.url
          }
        ]}
      />
     
      <div className="kb__container--full kb__article">
        <div className="kb__grid-center-column">
          <div className="kb__col-6_md-10_sm-12">
            {article.node.title &&
              <h1 className="sub-headline kb__article-title">{RichText.asText(article.node.title)}</h1>
            }
          </div>


          {article.node.body &&

              article.node.body.map((slice,i) => {

                if(slice){
                  
                  if (slice.type === "big_image") { 
                    return ( 
                      <div key={i} className="kb__col-10_md-12_sm-12">
                        <img src={slice.primary.big_image.url} alt={slice.primary.big_image.alt} />
                      </div>
                    )
                  } else if (slice.type === "rich_text_block") {
                    return(
                      <div key={i} className="kb__col-6_md-10_sm-12">
                        <section className="kb__blog-rich-text">
                          <RichText render={slice.primary.rich_text_content} linkResolver={linkResolver} />
                        </section>
                      </div>
                    )
                  } else if (slice.type === "normal_image") {
                    return(
                      <div key={i} className="kb__col-6_md-10_sm-12">
                        <img src={slice.primary.normal_image.url} alt={slice.primary.normal_image.alt} />
                      </div>
                    )
                  } else if (slice.type === "creator_spotlight") {
                    return(
                      <div key={i} className="kb__col-6_md-10_sm-12">
                        <section className="kb__grid-noGutter kb__creator-card">
                          <div className="kb__col-6_md-4_sm-12 kb__creator-card-img">
                            <img src={slice.primary.creator_image.url} alt="" />
                          </div>
                          <div className="kb__col-6_md-8_sm-12 kb__creator-card-panel">
                            {RichText.render(slice.primary.creator_blurb)}
                          </div>
                        </section>
                      </div>
                    )
                  } else if (slice.type === "custom_html") {

                    function createMarkup() {
                      return {__html: RichText.asText(slice.primary.custom_html)};
                    }

                    return(
                      <div key={i} className="kb__col-6_md-10_sm-12">
                        <div dangerouslySetInnerHTML={createMarkup()} />
                      </div>
                    )
                  } else if (slice.type === "sharing") { 

                      var currentUrl = 'https://kickbooster.me'+RichLink(article.node);

                      return ( 
                        <div key={i} className="kb__col-10_md-12_sm-12">
                          <section className="kb__article-sharing">
                            <FacebookShareButton url={currentUrl}><FacebookIcon /></FacebookShareButton>
                            <TwitterShareButton url={currentUrl}><TwitterIcon /></TwitterShareButton>
                            <EmailShareButton url={currentUrl}><EmailIcon /></EmailShareButton>
                          </section>
                        </div>
                      )
                  } else if (slice.type === "author_block") {
                    return(
                      <div key={i} className="kb__col-6_md-10_sm-12">
                        <section className="kb__grid-noGutter kb__creator-card">
                          <div className="kb__col-6_md-4_sm-12 kb__creator-card-img">
                            <img src={slice.primary.author_image.url} alt="" />
                          </div>
                          <div className="kb__col-6_md-8_sm-12 kb__creator-card-panel">
                            {RichText.render(slice.primary.author_blurb)}
                          </div>
                        </section>
                      </div>
                    )

                  } else if (slice.type === "pre-launch_gated_content") {
                    return(
                      <PrelaunchGatedContent key={i} gatedSection={slice} />
                    )
                  } else if (slice.type === "p-manager_demo_form"){
                    return(
                      <PManagerDemoForm key={i} pmDemoForm={slice} />
                    )
                  } else {
                    return null
                  }

                //Not a slice
                } else {
                  return null
                }
              })
            }

        </div>
      </div>
    </Layout>
  );
}

export default Post;