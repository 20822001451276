import React, {Component} from 'react'

class GatedContentForm extends Component{
  
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://kickbooster.activehosted.com/f/embed.php?id=16';
    script.async = true;

    document.body.appendChild(script);
  }

  render(){
    return(
      <section className="kb__gated-content-form">
        <div className="kb__container">

            <div className="_form_16" />
          
        </div>
      </section>
    )
  }
}

export default GatedContentForm
