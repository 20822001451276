import React, {Component} from 'react'

class ActiveCampaignForm extends Component{
  
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://kickbooster.activehosted.com/f/embed.php?id=' + this.props.form_id;
    script.async = true;

    document.body.appendChild(script);
  }


  render(){
    
    return(
      <section className="kb__modal-form">
        <div className="kb__container">

            <div className={"_form_" + this.props.form_id} />
          
        </div>

        <style dangerouslySetInnerHTML={{__html: `
          .kb__modal-form form p,
          .kb__modal-form form ._form-title{
            text-align: center !important;
          }
          
          ._x45222946 label{
            text-align: center !important;
          }
          ._x45222946 .g-recaptcha{
            display: flex;
            justify-content: center;
          }

          .kb__modal-form form ._button-wrapper{
            display: flex;
            justify-content: center;
          }
          .kb__modal-form form ._button-wrapper button{font-size: 16px !important;}
        `}} />
      </section>
    )
  }
}

export default ActiveCampaignForm
