import React, { useState } from 'react'
import { RichText} from 'prismic-reactjs'
import ReactModal from 'react-modal'
import ActiveCampaignForm from './activeCampaignForm'
import IconClose from '../images/icon-close.svg'

import './pManagerGatedDemo.scss'
import './_modal.scss'


ReactModal.setAppElement('#___gatsby');


const PManagerDemoForm = (props) => {

  const [showModal, setShowModal] = useState(false);

  function handleOpenModal() {
    setShowModal(true);
  }
  
  function handleCloseModal() {
    setShowModal(false);
  }

  if(!props.pmDemoForm) return null;

  var innerAnchor;

  if(props.pmDemoForm.primary.pmanager_cta_text){
    innerAnchor = props.pmDemoForm.primary.pmanager_cta_text.replace(/\s/g, '-').toLowerCase();
  } else {
    innerAnchor = "rich-text-"+props.count;
  }
  

  return (
    <>
    <section className="kb__rich-text-block" id={innerAnchor} data-center-layout="true">
      <div className="kb__container">
        <div className="kb__grid-center">

          <div className="kb__col-6_sm-12">
            {RichText.render(props.pmDemoForm.primary.pmanager_blurb)}

            <button onClick={handleOpenModal} className="kb__button--primary kb__button--large">{props.pmDemoForm.primary.pmanager_cta_text}</button>
          </div>
        </div>
      </div>
    </section>

    <ReactModal 
      isOpen={showModal}
      contentLabel="Contact Form"
      onRequestClose={handleCloseModal}
      className="kb__modal"
      overlayClassName="kb__overlay"
    >
      <button className="kb__modal--close" onClick={handleCloseModal}><img src={IconClose} alt="Close icon"/></button>
      
      <ActiveCampaignForm form_id={props.pmDemoForm.primary.ac_form_id} />
        
    </ReactModal>
    
    </>
  )
}

export default PManagerDemoForm
