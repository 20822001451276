import React, { useState } from 'react'
import { RichText} from 'prismic-reactjs'
import ReactModal from 'react-modal'
import GatedContentForm from './gatedContentForm'
import IconClose from '../images/icon-close.svg'

import './preLaunchGatedContent.scss'
import './_modal.scss'


ReactModal.setAppElement('#___gatsby');


const PrelaunchGatedContent = (props) => {

  const [showModal, setShowModal] = useState(false);

  function handleOpenModal() {
    setShowModal(true);
  }
  
  function handleCloseModal() {
    setShowModal(false);
  }
  
  if(!props.gatedSection) return null;
  

  return (
    <>
    <div className="kb__col-6_md-10_sm-12">
      <section className="kb__grid-center-middle kb__gated-content">
        <div className="kb__col-6_sm-12 kb__gated-content-info">
          {RichText.render(props.gatedSection.primary.gated_section_title)}
          {RichText.render(props.gatedSection.primary.gated_section_blurb)}

          <button onClick={handleOpenModal} className="kb__button--primary">{RichText.asText(props.gatedSection.primary.gated_section_cta_text)}</button>

        </div>

        <div className="kb__col-5_sm-8 kb__gated-content-img">
          <img src={props.gatedSection.primary.gated_section_image.url} alt={props.gatedSection.primary.gated_section_image.alt} />
        </div>
      </section>
    </div>

    <ReactModal 
      isOpen={showModal}
      contentLabel="Pre-Launch Guide"
      onRequestClose={handleCloseModal}
      className="kb__modal"
      overlayClassName="kb__overlay"
    >
      <button className="kb__modal--close" onClick={handleCloseModal}><img src={IconClose} alt="Close icon"/></button>
      
      <GatedContentForm />

    </ReactModal>
    
    </>
  )
}

export default PrelaunchGatedContent
